/* body {
  background-color: #002269;
} */

/* svg-background */
.background-svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

/* svg-background */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* svg-background */
.background-top-left {
  fill: #181818;
  stroke: #5d5b5b; /* Border color */
  stroke-width: 0.05; /* Border width */
  animation: rotate 10s linear infinite;
  transform-origin: 13px 25px;
}

/* svg-background */
.background-bottom-right {
  fill: #181818;
  stroke: #5d5b5b; /* Border color */
  stroke-width: 0.05; /* Border width */
  animation: rotate 25s linear infinite;
  transform-origin: 84px 93px;
}
