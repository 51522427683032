.language-switcher-container {  
  position: relative; /* This is needed to position the dropdown menu */  
}  

.language-switcher {
  position: relative;
  display: inline-block;
  margin-top: "0.25rem";
}

.language-switcher-button {
  background: transparent;
  border: 1px solid #ccc;
  padding: 1px 1px;
  cursor: pointer;
  outline: none;
}

.language-icon {  
  color: whitesmoke;
  font-size: 1.5rem;
}  


/* .language-switcher-dropdown {
  position: absolute;
  background: #DCDCDC;
  border: 1px solid black;  
  width: 100%;
  z-index: 10;
} */

.language-switcher-dropdown {  
  position: absolute;  
  left: 50%; /* Start the dropdown in the middle of the parent */  
  transform: translateX(-50%); /* Shift the dropdown back by half its own width */  
  background-color: #fff;  
  border: 1px solid #ccc;  
  border-radius: 4px;  
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);  
  padding: 0;  
  margin-top: 5px;  
  z-index: 1000; /* Ensure it's above other elements */  
}  

/* .language-switcher-option {
  display: block;
  width: 100%;
  padding: 1px 1px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
} */

.language-switcher-option {  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  width: 100%;  
  padding: 8px 16px;  
  text-align: left;  
  border: none;  
  background: none;  
  cursor: pointer;  
  border-bottom: 1px solid #eaeaea;
}  

/* Remove the border from the last option to avoid double borders at the bottom */  
.language-switcher-option:last-child {  
  border-bottom: none;  
}  

/* .language-switcher-option:hover,
.language-switcher-option.selected {
  background-color: transparent;
} */

.language-switcher-option:hover,  
.language-switcher-option:focus {  
  background-color: #f8f9fa;  
}  
  
.language-switcher-option.selected {  
  font-weight: bold;  
}  

.checkmark {  
  display: inline-block;  
  margin-left: 8px;  
  /* You can add more styles here if needed */  
}  

.language-switcher-option.selected::after {  
  content: ' \2713';  
  margin-left: 8px;  
}  


.flag-icon {
  margin-right: 5px;
}
