.dark-mode-header {
  background-color: #2e2e2e !important;
  /* border-color: #dbe2ea;   */
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 300;
  /* border-color: red; */
  /* border-top-color: green; */
  border-bottom-color: rgb(93, 93, 93) !important;
  border-style: solid;
  border-width: 1px;
}

.dark-mode-title {
  background-color: transparent !important;
  /* border-color: #dbe2ea;   */
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 300;
  /* border-color: red; */
  /* border-style: solid; */
}

.dark-mode-body {
  background-color: #2e2e2e !important;
  /* border-color: #dbe2ea;   */
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 300;
  /* border-color: red; */
  /* border-style: solid; */
  border-left-style: solid;
  border-right-style: solid;
  border-width: 1px;
}

.dark-mode-footer {
  background-color: #2e2e2e !important;
  /* border-color: #dbe2ea;   */
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 300;
  /* border-color: red; */
  border-top-color: rgb(93, 93, 93) !important;
  border-style: solid;
  border-width: 1px;
}

.dark-mode-btn {
  background-color: black !important;
  color: #ffffff;
  /* border-color: whitesmoke !important; */
  border-color: transparent !important;
}

.dark-mode-btn:hover {
  background-color: #1a1a1a !important;
}
